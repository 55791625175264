import React from 'react'
import styled from 'styled-components'

export type DoubleTriangleSvgProps = { width:number; height:number}
export const StyledSvgIcon = styled.svg`
  .a {
    fill: url(#a);
  }

  .b {
    fill: #e6e6e6;
    opacity: 0.647;
    mix-blend-mode: multiply;
    isolation: isolate;
  }
`

export const DoubleTriangleSvg: React.FC<DoubleTriangleSvgProps> = ({ width, height, ...props }) => {  
  return (
    <StyledSvgIcon xmlns="http://www.w3.org/2000/svg" {...props} width={width} height={height} viewBox="0 0 142.405 179.779">
      <defs>
        <linearGradient id="a" x1="0.5" x2="0.5" y2="1" gradientUnits="objectBoundingBox">
          <stop offset="0" stopColor="#ffb81c"/>
          <stop offset="1" stopColor="#f35a21"/>
        </linearGradient>
      </defs>
      <g transform="translate(-1441.956 -325.221)" >
        <path className="a" d="M14146-3475.438l-42.258-123.065h85.9Z" transform="translate(-12605.281 3980.438)"/>
        <path className="b"  d="M14138.872-3486.012l-75.5-139.561h122.406Z" transform="translate(-12621.414 3950.793)"/>
      </g>
    </StyledSvgIcon>
  )
}